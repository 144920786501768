import jwtDefaultConfig from "./jwtDefaultConfig";
import useJwt from "./useJwt";
import router from "@/router";
import Vue from "vue";
import store from "@/store";

import VueI18n from "@/libs/i18n";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Notification
export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;
  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;
  j;

  // For Refreshing Token
  subscribers = [];
  errorCount = 0;
  firstRequest = true;
  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };
    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        // router.replace(getHomeRouteForLoggedInUser());
        var userData = JSON.parse(localStorage.getItem("userData"));

        const accessToken = this.getToken();
        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
          const locale = localStorage.getItem("locale");
          config.headers.plantid = store.state.app.selectedPlantId;
          config.headers.modelid = store.state.app.selectedModelId;
          config.headers.model = store.state.app.selectedModelId;
          config.headers.culinfo = locale;
          config.headers.culture = locale;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => {
        // if (response.data.status === "ERROR")
        this.errorCount = 0;
        store.dispatch("app/setError", this.errorCount);
        store.dispatch("app/setFirstRequest", false);

        return response;
      },
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;
        // if (status === 401) {
        if (response && (response.status === 401 || response.status === 403)) {
          if (!this.isAlreadyFetchingAccessToken) {
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);

            // Remove userData from localStorage
            localStorage.removeItem("userData");
            // Reset ability
            this.errorCount += 1;
            if (this.errorCount === 1)
              new Vue().$toast(VueI18n.t("sesionExpired"));
            // Redirect to login page

            // this.isAlreadyFetchingAccessToken = true;
            router
              .push({ name: "auth-login" })
              .then(() => {
                store.dispatch("app/setError", this.errorCount);
                store.dispatch("app/setFirstRequest", false);
              })
              .catch(() => {});
            // this.$ability.update(initialAbility);
            // this.refreshToken().then((r) => {
            //   this.isAlreadyFetchingAccessToken = false;

            //   // Update accessToken in localStorage
            //   this.setToken(r.data.token);
            //   this.setRefreshToken(r.data.refreshToken);

            //   this.onAccessTokenFetched(r.data.token);
            // });
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(args, headers) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, args, headers);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }
}
