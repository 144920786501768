<template>
  <!-- <Logo /> -->
  <b-img :src="img" alt="logo" style="height: 50px" />
</template>
<script>
import { $themeConfig } from "@themeConfig";
import { BImg } from "bootstrap-vue";
// import Logo from "@/assets/images/logo/logo.svg";
export default {
  components: {
    BImg,
    // Logo,
  },
  data: function () {
    return { img: require("@/assets/images/logo/logo.png") };
  },
};
</script>
